import axios from "axios"
import { find, findAll } from "utils/dom"

export default function modalProdutoApi(refSwiper) {
    // product-content-wrapper  produto-item 

    const changeProductItems = findAll('[js-tabs-produto] li')
    const produtosItems = findAll('.product-content-wrapper .produto-item ')
    const closeModal = find('.close-modal')

    const fakeData = {
        1: {
            descricao: `Matinal é um Leite Selecionado porque elege a melhor proveniência e os máximos padrões de qualidade em todas as etapas. Por este motivo, as propriedades nutricionais e qualitativas do melhor leite mantêm-se intactas, nomeadamente o seu teor proteico, mineral e vitamínico. Um leite da mais alta qualidade, de sabor inigualável, criteriosamente selecionado, auditado e certificado. Mais do que um leite, a afirmação simples da excelência.`,
            vd: 'p/ copo de 250 ml',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "501/119", "VD": "6%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "4,0", "VD": "6%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "2,8", "VD": "14%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "12", "VD": "5%**" },
                { "itemNome": "açúcares (g)", "quantidade": "12", "VD": "14%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "8,5", "VD": "17%**", "classe": "escuro" },
                { "itemNome": "sal (g) 1)", "quantidade": "0,23", "VD": "4%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "riboflavina (mg)", "quantidade": "0,33", "VD": "23%*", "classe": "escuro avancado" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,50", "VD": "20%*", "classe": "escuro avancado" },
                { "itemNome": "potássio (mg)", "quantidade": "418", "VD": "21%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "300", "VD": "38%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "233", "VD": "33%*", "classe": "escuro avancado" },
                { "itemNome": "iodo (µg)", "quantidade": "75,0", "VD": "50%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal); 1) Teor de sal deve-se exclusivamente ao sódio naturalmente presente.
            A embalagem contém 4 porções de 250 ml.`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        2: {
            descricao: `O mesmo Leite Seleccionado Matinal, com menos calorias e menos gordura. A escolha de quem segue uma dieta alimentar mais controlada e procura o equilíbrio, sem abdicar do sabor inigualável do Leite Seleccionado Matinal. `,
            vd: 'p/ copo de 250 ml',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "371/87", "VD": "4%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "0,4", "VD": "1%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "0,2", "VD": "1%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "13", "VD": "5%**" },
                { "itemNome": "açúcares (g)", "quantidade": "13", "VD": "14%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "8,5", "VD": "17%**", "classe": "escuro" },
                { "itemNome": "sal (g) 1)", "quantidade": "0,25", "VD": "4%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "riboflavina (mg)", "quantidade": "0,40", "VD": "29%*", "classe": "escuro avancado" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,50", "VD": "20%*", "classe": "escuro avancado" },
                { "itemNome": "potássio (mg)", "quantidade": "398", "VD": "20%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "308", "VD": "38%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "235", "VD": "34%*", "classe": "escuro avancado" },
                { "itemNome": "iodo (µg)", "quantidade": "65,0", "VD": "43%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal); 1) Teor de sal deve-se exclusivamente ao sódio naturalmente presente.
            A embalagem contém 4 porções de 250 ml.`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        3: {
            descricao: `A evolução do leite. O Leite Seleccionado Matinal Leve é um leite sem gordura, fonte natural de cálcio, que contribui para o bem-estar digestivo *. Com uma redução de 50% de lactose, é ideal para quem se preocupa com o seu estilo de vida e em manter uma alimentação saudável, sem prescindir de todo o sabor natural de Matinal.<br/>
            Disponível em embalagem de 1 L e 200 ml.<br/>
            * O leite magro, sem gordura, é fonte natural de cálcio que contribui para o normal funcionamento das enzimas digestivas, para o seu bem-estar digestivo. Teor de gordura reduzido relativamente a um leite meio-gordo.`,
            vd: 'p/ copo de 250 ml',
            selo: 'selo-leve',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "357/84", "VD": "4%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "0", "VD": "0%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "0", "VD": "0%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "13", "VD": "5%**" },
                { "itemNome": "açúcares (g)", "quantidade": "13", "VD": "14%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "8,5", "VD": "17%**", "classe": "escuro" },
                { "itemNome": "sal (g) 1)", "quantidade": "0,25", "VD": "4%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "riboflavina (mg)", "quantidade": "0,40", "VD": "29%*", "classe": "escuro avancado" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,50", "VD": "20%*", "classe": "escuro avancado" },
                { "itemNome": "potássio (mg)", "quantidade": "398", "VD": "20%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "308", "VD": "38%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "235", "VD": "34%*", "classe": "escuro avancado" },
                { "itemNome": "iodo (µg)", "quantidade": "65,0", "VD": "43%*", "classe": "escuro avancado" },
            ],
            complemento: `Lactose 2,5g por 100ml. A redução do teor de lactose é obtida pela conversão desta em glicose e galactose. Um leite magro tem lactose 5g por 100ml.
            * do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal); 1) Teor de sal deve-se exclusivamente ao sódio naturalmente presente. A embalagem contém 4 porções de 250 ml.`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        4: {
            descricao: `Inovação a pensar no bem-estar digestivo e imunitário*, Matinal Livre é o primeiro Matinal meio-gordo, sem lactose, com fermentado de bifidobactérias tindalizadas e vitamina D.<br/>
            Uma escolha pioneira, que contribui para o bem-estar digestivo e imunitário de toda a família. Matinal Livre é para toda a família e para intolerantes à lactose.<br/>
            * O leite é fonte natural de cálcio que contribui para o normal funcionamento das enzimas digestivas, para o bem-estar digestivo. Matinal Livre é rico em vitamina D, que contribui para o normal funcionamento do sistema imunitário.`,
            vd: 'p/ copo de 250 ml',
            selo: 'selo-livre',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "501/119", "VD": "6%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "4,0", "VD": "6%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "2,8", "VD": "14%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "12", "VD": "5%**" },
                { "itemNome": "açúcares (g)", "quantidade": "12", "VD": "14%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "8,5", "VD": "17%**", "classe": "escuro" },
                { "itemNome": "sal (g)", "quantidade": "0,23", "VD": "4%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "vitamina D (µg)", "quantidade": "1,9", "VD": "38%", "classe": "escuro avancado" },
                { "itemNome": "riboflavina (mg)", "quantidade": "0,33", "VD": "23%*", "classe": "escuro avancado" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,50", "VD": "20%*", "classe": "escuro avancado" },
                { "itemNome": "potássio (mg)", "quantidade": "418", "VD": "21%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "300", "VD": "38%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "233", "VD": "33%*", "classe": "escuro avancado" },
                { "itemNome": "iodo (µg)", "quantidade": "62,5", "VD": "42%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal); A embalagem contém 4 porções de 250 ml. A redução do teor de lactose é obtida pela conversão desta em glicose e galactose. 1 250 milhões de Bifidobacterium longum CECT 7347 tindalizado por copo de 250 ml
            As bifidobactérias tindalizadas são submetidas a um tratamento térmico de forma a não alterar as características organoléticas do leite.
            <b>Ingredientes: Leite</b> meio-gordo, Bifidobacterium longum CECT 7347 tindalizado, lactase e vitamina D.
            Sem lactose. Apto para intolerantes à lactose. Não apto para alérgicos às proteínas do leite de vaca. Em caso de dúvida, consulte o médico.`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        5: {
            descricao: `O queijo fresco Matinal incentiva um estilo de vida saudável, promovendo soluções de refeições rápidas e nutricionalmente equilibradas. Rico em proteína e com 0% de conservantes, é uma opção inovadora, perfeita para um snack ou uma refeição leve, simples ou combinado em saladas ou com fruta.`,
            vd: 'por porção 72g',
            selo: 'selo-rico',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "491/118", "VD": "6%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "8,6", "VD": "12%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "6,0", "VD": "30%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "2,2", "VD": "1%**" },
                { "itemNome": "açúcares (g)", "quantidade": "2,2", "VD": "2%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "7,9", "VD": "16%**", "classe": "escuro" },
                { "itemNome": "sal (g)", "quantidade": "0,64", "VD": "11%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "vitamina A (µg)", "quantidade": "87,1", "VD": "11%*", "classe": "escuro avancado" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,55", "VD": "22%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "245", "VD": "31%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "173", "VD": "25%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal). Esta embalagem contém 3 porções de 72g`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        6: {
            descricao: `O queijo fresco Matinal incentiva um estilo de vida saudável, promovendo soluções de refeições rápidas e nutricionalmente equilibradas. Rico em proteína e com 0% de conservantes, é uma opção inovadora, perfeita para um snack ou uma refeição leve, simples ou combinado em saladas ou com fruta.`,
            vd: 'por porção 72g',
            selo: 'selo-rico',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "179/42", "VD": "2%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "0", "VD": "0%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "0", "VD": "0%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "2,6", "VD": "1%**" },
                { "itemNome": "açúcares (g)", "quantidade": "2,6", "VD": "3%**" },
                { "itemNome": "proteínas (g)", "quantidade": "7,9", "VD": "16%**", "classe": "escuro" },
                { "itemNome": "sal (g)", "quantidade": "0,64", "VD": "11%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,29", "VD": "12%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "216", "VD": "27%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "144", "VD": "21%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal).`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        7: {
            descricao: `O queijo fresco Matinal incentiva um estilo de vida saudável, promovendo soluções de refeições rápidas e nutricionalmente equilibradas. Rico em proteína e com 0% de conservantes, é uma opção inovadora, perfeita para um snack ou uma refeição leve, simples ou combinado em saladas ou com fruta. Sem lactose, é ideal para quem se preocupa com o seu estilo de vida e em manter uma alimentação saudável, sem prescindir do sabor.`,
            vd: 'por porção 72g',
            selo: 'selo-rico',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "491/118", "VD": "6%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "8,6", "VD": "12%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "6,0", "VD": "30%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "2,2", "VD": "1%**" },
                { "itemNome": "açúcares (g)", "quantidade": "2,2", "VD": "2%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "7,9", "VD": "16%**", "classe": "escuro" },
                { "itemNome": "sal (g)", "quantidade": "0,64", "VD": "11%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "cálcio (mg)", "quantidade": "245", "VD": "31%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "158", "VD": "23%*", "classe": "escuro avancado" },
                { "itemNome": "Valores médios", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "lactose (g)", "quantidade": "<0,01", "VD": "", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal). A embalagem contém 3 porções de 72g.`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        8: {
            descricao: `O Queijo Fresco Matinal +Proteína oferece 30 gramas de proteína por embalagem. Uma nova escolha para quem pretende reforçar a proteína na alimentação, de forma simples e saborosa. + Proteína tem um baixo teor de gordura e é especialmente rico em proteínas, que contribuem para o crescimento e manutenção da massa muscular.`,
            vd: 'por 100g',
            selo: 'selo-proteina',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "410/97", "VD": "5%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "2,8", "VD": "4%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "1,9", "VD": "10%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "4,0", "VD": "2%**" },
                { "itemNome": "açúcares (g)", "quantidade": "3,3", "VD": "4%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "14", "VD": "28%**", "classe": "escuro" },
                { "itemNome": "sal (g)", "quantidade": "1,1", "VD": "18%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "vitamina B12 (µg)", "quantidade": "0,38", "VD": "15%*", "classe": "escuro avancado" },
                { "itemNome": "cálcio (mg)", "quantidade": "400", "VD": "50%*", "classe": "escuro avancado" },
                { "itemNome": "fósforo (mg)", "quantidade": "230", "VD": "33%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN);
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal)`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        9: {
            descricao: `Matinal Cottage é uma nova forma de consumo de queijo fresco. Uma escolha versátil, com um granulado macio, que inspira a criação de diferentes combinações e receitas. Com 23 gramas de proteína, baixo em gordura e sem glúten, é perfeito para barrar, complementar saladas ou combinar com frutas e cereais. Ideal para quem aprecia uma refeição leve e para os amantes do desporto.`,
            vd: 'por unid. 180g',
            selo: 'selo-Cott',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "780/186", "VD": "9%*" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "7,2", "VD": "10%*", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "4,9", "VD": "24%*", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "6,8", "VD": "3%*" },
                { "itemNome": "açúcares (g)", "quantidade": "6,8", "VD": "8%*", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "23", "VD": "47%*", "classe": "escuro" },
                { "itemNome": "sal (g)", "quantidade": "1,4", "VD": "24%*" },
            ],
            complemento: `* da Dose de Referência (DR) para um adulto médio (8400 kJ/ 2000 kcal)`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        10: {
            descricao: `Reconhecidamente única, a Manteiga Matinal Magra tem menos 45% de gordura e menos 45% de calorias, quando comparada com uma manteiga com sal. Com um sabor suave e distinto, muito fácil de barrar, é a escolha perfeita para quem não abdica do prazer de uma manteiga de qualidade superior, numa opção mais leve.`,
            vd: '1 c. de chá (5g)',
            selo: 'selo-45',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "79/19", "VD": "1%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "2,1", "VD": "3%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "1,2", "VD": "6%**", "classe": "escuro avancado" },
                { "itemNome": "monoinsaturados (g)", "quantidade": "0,6", "VD": "", "classe": "escuro avancado" },
                { "itemNome": "polinsaturados (g)", "quantidade": "0,05", "VD": "", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "0,10", "VD": "0%**" },
                { "itemNome": "açúcares (g)", "quantidade": "0", "VD": "0%**", "classe": "avancado" },
                { "itemNome": "proteínas (g)", "quantidade": "0,09", "VD": "0%**", "classe": "escuro " },
                { "itemNome": "sal (g)", "quantidade": "0,07", "VD": "1%**" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                { "itemNome": "vitamina A (µg)", "quantidade": "23,2", "VD": "3%*", "classe": "escuro avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN).
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/2000 kcal). A embalagem contém 50 porções de 5g. 
            <b>INGREDIENTES:</b> Água, <b>matéria gorda láctea, proteínas de leite,</b> amido modificado, sal: 1,2%, emulsionantes: mono e diglicéridos de ácidos gordos e polirricinoleato de poliglicerol, aromas naturais, conservante: sorbato de potássio e corante: carotenos.
            <b>Não recomendado para culinária.</b>`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
        11: {
            descricao: `Com -85% de gordura e -80% de calorias, Matinal magro sem Lactose é a escolha perfeita para dietas com restrição calórica ou para quem é intolerante à lactose. Mais um produto inovador de Matinal, com a leveza e a simplicidade que distingue a marca, a pensar nas necessidades específicas dos consumidores.`,
            vd: '1 c. de chá (5g)',
            selo: 'selo-85',
            fichaNutricional: [
                { "itemNome": "energia (kJ/kcal)", "quantidade": "24/6", "VD": "0%**" },
                { "itemNome": "lípidos (g) dos quais:", "quantidade": "0,5", "VD": "1%**", "classe": "escuro" },
                { "itemNome": "saturados (g)", "quantidade": "0,3", "VD": "2%**", "classe": "escuro avancado" },
                { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "0,21", "VD": "0%**" },
                { "itemNome": "açúcares (g)", "quantidade": "0", "VD": "0%**", "classe": "avancado" },
                { "itemNome": "Fibra (g)", "quantidade": "0,03", "VD": "", "classe": "escuro" },
                { "itemNome": "proteínas (g)", "quantidade": "0", "VD": "0%**" },
                { "itemNome": "sal (g)", "quantidade": "0,10", "VD": "2%**", "classe": "escuro" },
                { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "" },
                { "itemNome": "vitamina A (µg)", "quantidade": "10,0", "VD": "1%*", "classe": "avancado" },
                { "itemNome": "vitamina E (mg)", "quantidade": "0,090", "VD": "1%*", "classe": "avancado" },
            ],
            complemento: `* do Valor de Referência do Nutriente (VRN).
            ** da Dose de Referência (DR) para um adulto médio (8400 kJ/2000 kcal). A embalagem contém 40 porções de 5g. 
            <b>Creme lácteo para barrar 10% M.G. magro com sal</b>
            <b>INGREDIENTES:</b>  Água, <b>matéria gorda láctea</b>, maltodextrina, sal: 2%, aroma natural, espessante: alginato de sódio, emulsionantes: mono e diglicéridos de ácidos gordos e polirricinoleato de poliglicerol, <b>ácido láctico concentrado</b>, conservante: sorbato de potássio, vitaminas: E e A e corante: carotenos.
            <b>Não recomendado para culinária.</b>`,
            linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf"
        },
    }

    function renderModal(data) {
        const conteudo = find("[js-modal-content-custom]")
        const desc = data.descricao.replace(/\r?\n/g, '<br>');
        const complemento = data.complemento?.replace(/\r?\n/g, '<br>') || '';
        const vdTH = data.vd || 'Por 100g';

        const htmlFicha = data.fichaNutricional.length > 0 && data.fichaNutricional.map(ficha => {
            return `
              <tr class="${ficha.classe || ''}">
                <td class="text-paragraph-4 !font-normal text-primary-pure">
                    <span>${ficha.itemNome}</span>
                    <span class="float-right">${ficha.quantidade}</span>
                </td>

                <td class="text-paragraph-4 text-primary-pure">${ficha.VD}</td>
              </tr>
            `
        }).join('')

        const HTMLModal = `
            <div class="flex justify-between gap-20">
                <div class="bloco-content top mb-32 max-w-[34rem]">
                    <h2 class="text-neutral-70 text-headline-3 mb-8">Descrição</h2>
                    <div class="box-texto">
                        ${desc}
                    </div>
                </div>

                ${(data.selo) ? `<img loading="lazy" class="selo-img" src="${data.selo}" sm:hidden" />` : ''
            }
            </div>

            <div class="bloco-content mb-32 ${data.fichaNutricional.length > 0 ? '' : 'hidden'}">
                <h2 class="text-neutral-70 text-headline-3 mb-12">
                    Ficha nutricional
                </h2>

                <table>
                    <tr>
                        <th class="text-headline-4 text-primary-pure">Valores médios</th>
                        <th class="text-headline-4 text-primary-pure">${vdTH}</th>
                    </tr>
                    ${htmlFicha}
                </table>

                <div class="bloco-content max-w-[34rem]">
                    ${complemento}
                </div>
            </div>


            <div class="bellow-share flex items-center gap-32">
                <div class=" border-r  border-neutral-100/10 pr-32">
                    <span class="text-headline-3 text-neutral-70 mb-8 block">Compartilhe via:</span>
                    <div class="icons flex items-center gap-8">
                        <div class="facebookShare">
                            <a >
                                <svg  class="w-24 h-24">
                                    <use xlink:href="#icon_face-share"></use>
                                </svg>

                            </a>
                        </div>
                        <div class="whatsappShare">
                            <a >
                                <svg class="w-24 h-24">
                                    <use xlink:href="#icon_whats-share"></use>
                                </svg>

                            </a>
                        </div>
                        <div class="twitterShare">
                            <a >
                                <svg class="w-24 h-24">
                                    <use xlink:href="#icon_twitter-share"></use>
                                </svg>

                            </a>
                        </div>
                        <div class="copyShare">
                            <a >
                                <svg class="w-24 h-24">
                                    <use xlink:href="#icon_copy-share"></use>
                                </svg>

                            </a>
                        </div>
                    
                    </div>
                </div>
                

                <a download href="${data.linkDownload}" target="_blank" class="Button" size="md" outline>
                    <svg class="w-24 h-24">
                        <use xlink:href="#share-file"></use>
                    </svg>
                    Fazer download
                </a>

            </div>
        `

        conteudo.innerHTML = HTMLModal
    }

    function getData(id) {


        // simular sem o back ' remover depois que o back implementar '
        renderModal(fakeData[id])
        return

    }

    function text_editor_js(text_editor) {
        if (text_editor && text_editor != ' ') {
            let Texto = JSON.parse(text_editor);
            let ResultText = '';
            let TotalTag = Texto.blocks.length;

            for (let i = 0; i < TotalTag; i++) {
                const Tipo = Texto.blocks[i].type;
                if (Tipo == 'paragraph') {
                    let ParagraphTexto = Texto.blocks[i].data.text;
                    ResultText += `<p>${ParagraphTexto}</p>`
                }
                if (Tipo == 'Header') {
                    const Level = Texto.blocks[i].data.level
                    ResultText += `<h${Level}>${Texto.blocks[i].data.text}</h${Level}>`;
                }

                if (Tipo == 'List') {
                    const Items = Texto.blocks[i].data.items
                    let Lista = `<ul>`
                    for (let Item of Items) {
                        Lista += `<li>${Item}</li>`
                    }
                    Lista += `</ul>`
                    ResultText += Lista
                }
                if (Tipo == 'Delimiter') {
                    ResultText += `<br />`
                }

            }

            return ResultText;
        }
        else {
            return "";
        }
    }

    async function getData_2(id_produto, id_categoria) {

        const protocolo = window.location.protocol;
        const url = window.location.host;


        const produto = await axios.get(`${protocolo}//${url}/categorias/${id_categoria}/produtos`).then((response) => {
            const resultado_pesquisa = response.data;


            for (let pesquisa of resultado_pesquisa) {
                if (pesquisa.id == id_produto) {
                    const novo_objeto = {
                        descricao: text_editor_js(pesquisa.descricao_produto),
                        vd: pesquisa.medida_ficha,
                        fichaNutricional: [],
                        /*fichaNutricional: [
                            { "itemNome": "energia (kJ/kcal)", "quantidade": "501/119", "VD": "6%**" },
                            { "itemNome": "lípidos (g) dos quais:", "quantidade": "4,0", "VD": "6%**", "classe": "escuro" },
                            { "itemNome": "saturados (g)", "quantidade": "2,8", "VD": "14%**", "classe": "escuro avancado" },
                            { "itemNome": "hidratos de carbono (g) dos quais:", "quantidade": "12", "VD": "5%**" },
                            { "itemNome": "açúcares (g)", "quantidade": "12", "VD": "14%**", "classe": "avancado" },
                            { "itemNome": "proteínas (g)", "quantidade": "8,5", "VD": "17%**", "classe": "escuro" },
                            { "itemNome": "sal (g) 1)", "quantidade": "0,23", "VD": "4%**" },
                            { "itemNome": "Vitaminas e sais minerais", "quantidade": "", "VD": "", "classe": "escuro" },
                            { "itemNome": "riboflavina (mg)", "quantidade": "0,33", "VD": "23%*", "classe": "escuro avancado" },
                            { "itemNome": "vitamina B12 (µg)", "quantidade": "0,50", "VD": "20%*", "classe": "escuro avancado" },
                            { "itemNome": "potássio (mg)", "quantidade": "418", "VD": "21%*", "classe": "escuro avancado" },
                            { "itemNome": "cálcio (mg)", "quantidade": "300", "VD": "38%*", "classe": "escuro avancado" },
                            { "itemNome": "fósforo (mg)", "quantidade": "233", "VD": "33%*", "classe": "escuro avancado" },
                            { "itemNome": "iodo (µg)", "quantidade": "75,0", "VD": "50%*", "classe": "escuro avancado" },
                        ],*/
                        complemento: text_editor_js(pesquisa.informacoes),
                        linkDownload: "https://www.clickdimensions.com/links/TestPDFfile.pdf",
                        selo: `${(pesquisa.selo) ? pesquisa.selo : ''}`,
                    }

                    pesquisa.ficha_nutricional.sort((a, b) => {
                        if (a.ordem > b.ordem) {
                            return 1
                        }
                        else if (a.ordem < b.ordem) {
                            return -1
                        }
                        else return 0
                    })


                    for (let ficha of pesquisa.ficha_nutricional) {
                        novo_objeto.fichaNutricional.push({
                            "itemNome": ficha.nome,
                            "quantidade": ficha.quantidade,
                            "VD": `${(ficha.porcentagem) ? ficha.porcentagem : ""}`,
                            "classe": `${(ficha.cor == "Escuro") ? "escuro" : "claro"
                                } ${(ficha.item_ficha_pai) ? "avancado" : ""} `,
                        })
                    }



                    renderModal(novo_objeto);
                }
            }

            // console.log(response.data)
        });
        return
    }

    produtosItems.forEach(produto => {
        produto.addEventListener('click', (e) => {
            const target = e.target.closest('.produto-item')

            if (!target) return
            //const id = target.dataset.id

            const id_produto = target.dataset.produtoId;
            const id_categoria = target.dataset.categoriaId;

            const produtosWrapper = document.querySelector('.product-content-wrapper')
            produtosWrapper.classList.add('showModal')
            //getData(id)
            getData_2(id_produto, id_categoria)
        })
    });

    function handleCloseModal() {
        const produtosWrapper = document.querySelector('.product-content-wrapper')
        produtosWrapper.classList.remove('showModal')
    }

    if (closeModal) {
        closeModal.addEventListener('click', handleCloseModal)
        changeProductItems.forEach(item => item.addEventListener('click', handleCloseModal))
    }


    function sharedModal() {

        const modalProduto = document.querySelector('#modal-produto-item')
        if (modalProduto) {
            modalProduto.addEventListener('click', (e) => {

                const target = e.target.closest('.bellow-share .icons')
                if (!target) return

                if (e.target.closest('.facebookShare')) {
                    e.preventDefault();

                    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(window.location.href));
                }
                if (e.target.closest('.whatsappShare')) {
                    e.preventDefault(); // Evita o comportamento padrão do link
                    // Abre a página de compartilhamento do WhatsApp
                    window.open('https://api.whatsapp.com/send?text=' + encodeURIComponent(window.location.href));
                }
                if (e.target.closest('.twitterShare')) {
                    e.preventDefault(); // Evita o comportamento padrão do link
                    // Abre a página de compartilhamento do Twitter
                    window.open('https://twitter.com/intent/tweet?url=' + encodeURIComponent(window.location.href));
                }
                if (e.target.closest('.copyShare')) {
                    e.preventDefault(); // Evita o comportamento padrão do link
                    // Copia o link atual para a área de transferência
                    navigator.clipboard.writeText(window.location.href).then(function () {
                        alert('Link copiado para a área de transferência!');
                    }).catch(function () {
                        alert('Erro ao copiar o link.');
                    });
                }
            })
        }


    }



    // MUDAR CONTENT MODAL BASE ON SWIPER 
    refSwiper.forEach(ref => {
        ref.on('slideChange', () => {

            const index = ref.activeIndex
            const swiperEl = ref.slidesEl
            const activeSlideEl = swiperEl.querySelectorAll('.swiper-slide')[index]
            //const idFetch = activeSlideEl.querySelector('.produto-item').dataset.id
            const id_produto = activeSlideEl.querySelector('.produto-item').dataset.produtoId;
            const id_categoria = activeSlideEl.querySelector('.produto-item').dataset.categoriaId;
            // console.log( idFetch)
            const produtosWrapper = document.querySelector('.product-content-wrapper')
            if (produtosWrapper.classList.contains('showModal')) {
                getData_2(id_produto, id_categoria)

            }




        })
    })
    // refSwiper.on('slideChange',()=>{
    //     console.log('teste')
    // })
    sharedModal()

}