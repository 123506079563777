import { find, findAll } from "utils/dom"
import Swiper from "swiper"
import modalProdutoApi from "../modules/modalProdutoApi"
import animationCustomProdutos from "../animation/animationCustomProdutos"

export default function produtosPage() {

    const Produtos = find("[js-page='Produtos']")
    if (!Produtos) return


    const produtosSwiper = new Swiper('.swiper-produtos-items', {
        slidesPerView: 1.5,
        spaceBetween: 16,
        initialSlide: 0,
        slideToClickedSlide: true,
        watchSlidesVisibility: true,
        // loop:true,
        centeredSlides: true,
        centeredSlidesBounds: true,
        navigation: {
            nextEl: '.swiper-produtos-items .accordion-produto-right ',
            prevEl: '.swiper-produtos-items .accordion-produto-left '
        },
        pagination: {
            el: '.swiper-pagination',
            clickable: true
        },
        breakpoints: {
            767: {
                centeredSlidesBounds: false,
                initialSlide: 1,
                slidesPerView: 3,
                spaceBetween: 36,
            },
            1024: {
                centeredSlidesBounds: false,
                initialSlide: 1,
                slidesPerView: 3,
                spaceBetween: 136,
            }
        }
        // loopAddBlankSlides:true,
        // slideToClickedSlide:true,
        // centeredSlides: true,
        // centeredSlidesBounds:true
    })

    const swiperTabsProdutos = new Swiper('.swiper-tabs-produtos', {
        slidesPerView: 'auto',
        spaceBetween: 8,
        slideToClickedSlide: true
    })

    const tabs = findAll("[js-tabs-produto] li")
    const tabsContent = findAll(".produtos-content")
    console.log(tabs)
    if (tabs.length > 0) {
        tabs.forEach(tab => {
            tab.addEventListener('click', (e) => {
                const currentTab = e.target.closest('li')

                if (!currentTab) return

                if (currentTab.classList.contains('active-tab')) return
                const contentToShow = currentTab.dataset.content
                const id = document.querySelector(`#${contentToShow}`)
                tabs.forEach(i => i.classList.remove('active-tab'))
                tabsContent.forEach(z => z.classList.remove('show-tab'))
                currentTab.classList.add('active-tab')


                if (id) {
                    id.classList.add('show-tab')
                }

            })
        })
    }





    const hash = window.location.hash;

    if (hash && hash.startsWith('#item-')) {
        console.log(hash, 'hash')

        const number = hash.match(/\d+/);


        const tabNumber = parseInt(number[0]);


        const tabElement = document.querySelector(`li[data-content="produto-content-${tabNumber}"]`);
        console.log(tabElement)
        if (tabElement) {
            tabElement.click()
            const produtoSection = document.querySelector('#produtos-section')
            produtoSection.scrollIntoView({ behavior: 'smooth' });
            // tabElement.classList.add("active");
            // Add 'active' class to the tab
        }
    }


    // MODAL ON CLICK PRODUTOS  
    // PASSANDO REFERENCIA SWIPER PRA FAZER O FETCH 
    modalProdutoApi(produtosSwiper)

    document.addEventListener('DOMContentLoaded', () => {
        animationCustomProdutos();

    })
}