import Swiper from "swiper"
import { find } from "utils/dom"
import animationAccordion from "../animation/animationAccordion"
import animationCustomHome from "../animation/animationCustomHome"
import observerVideo from "../modules/observerVideo"

import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

export default function () {
	const Home = find("[js-page='home']")
	if (!Home) return


	const swiperInstagram = new Swiper(".swiper-acompanhe-nos", {
		slidesPerView: 5,
		spaceBetween: 0,

	})



	const swiperVerticalTimeline = new Swiper(".swiper-vertical-timeline", {
		direction: "horizontal",
		slidesPerView: 1,
		mousewheel: true,
		autoHeight: true,
		// slidesPerView: 1,
		// centeredSlides:true,
		// centeredSlidesBounds:true,
		// freeMode: true,
		watchSlidesProgress: true,
		breakpoints: {
			767: {
				direction: "vertical",
				slidesPerView: 'auto',
				centeredSlides: true,
			}
		}

	})

	const swiperTimeline = new Swiper(".swiper-timeline", {
		mousewheel: true,
		slidesPerView: 1,
		spaceBetween: 12,
		pagination: {
			el: ".swiper-timeline-pagination",
			clickable: true,
		},
		navigation: {
			nextEl: ".swiper-timeline-next",
			prevEl: ".swiper-timeline-prev",
		},
		// thumbs: {
		// 	swiper: swiperVerticalTimeline,
		//   },

	})

	swiperVerticalTimeline.controller.control = swiperTimeline;
	swiperTimeline.controller.control = swiperVerticalTimeline;

	var swiper = new Swiper(".mySwiper", {
		spaceBetween: 10,
		slidesPerView: 4,
		freeMode: true,
		watchSlidesProgress: true,
	});
	var swiper2 = new Swiper(".mySwiper2", {
		spaceBetween: 10,
		navigation: {
			nextEl: ".swiper-button-next",
			prevEl: ".swiper-button-prev",
		},
		thumbs: {
			swiper: swiper,
		},
	});
	var swiperBanner = new Swiper(".swiper-banner-home", {
		lazy: true,
		spaceBetween: 0,
		effect: 'fade',
		speed: 1000,
		//autoplay: false,
		autoplay: {
			delay: 8000,
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: ".swiper-banner-home .swiper-button-next",
			prevEl: ".swiper-banner-home .swiper-button-prev",
		}


		// navigation: {
		// 	nextEl: ".swiper-banner-home .swiper-button-next",
		// 	prevEl: ".swiper-banner-home .swiper-button-prev",
		// },

	});


	Fancybox.bind("[data-fancybox]", {
		// Your custom options
	})

	document.addEventListener('DOMContentLoaded', () => {
		animationCustomHome();
		animationAccordion()
		observerVideo()

		console.log(swiperBanner)
		setTimeout(() => {

			// swiperBanner.autoplay.start()
		}, 1000)
	})



}